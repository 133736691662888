import { enqueueSnackbar } from 'notistack'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  CertificateEmeraldBadgeIcon,
  CertificateGoldBadgeIcon,
  CertificatePlatinumBadgeIcon,
  CertificateSilverBadgeIcon,
  CertificateUSPlatinumBadgeIcon,
  ChevronRightIcon
} from '@opswat/react-icon'
import {
  Box,
  BoxAdvanced,
  Button,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  TemplateSection,
  Tooltip,
  Typography
} from '@opswat/react-ui'

import _find from 'lodash/find'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import _size from 'lodash/size'

import { useLazyOrganizationSupportInfoQuery, useSubscribeCriticalUserMutation } from 'myopswat-web/src/api/support'
import { useTypedSelector } from 'myopswat-web/src/store'

import { myOrganizationPageURL } from 'myopswat-web/src/routes'
import InformationCard from './InformationCard'
import OrganizationCaseAccess from './OrganizationCaseAccess'
import PhoneSupportSection from './PhoneSupportSection'
import { SYSTEM_VALUE_KEYS } from 'myopswat-web/src/constants'
import useHasPermissions from '../../../hooks/useHasPermissions'

interface IProps {
  companyInfo: any
  isLoading: boolean
  systemValueInfo: any[]
  permissions: string[]
}

const UserInformation: FC<IProps> = ({ companyInfo, isLoading, systemValueInfo, permissions }) => {
  const hasViewSupportServicePerm = useHasPermissions({targetPerms: ['view_support_services', 'full_support_services'], userPerms: permissions})
  const hasFullSupportServicePerm = useHasPermissions({targetPerms: ['full_support_services'], userPerms: permissions})

  const { t: translate } = useTranslation()
  const navigate = useNavigate()
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const [subscribeCriticalUser] = useSubscribeCriticalUserMutation()
  const [getOrganizationSupportInfo] = useLazyOrganizationSupportInfoQuery()

  const isSubscribeCritical = useMemo(() => {
    return !!_find(_get(companyInfo, 'criticalAlertUsers'), item => _get(item, 'email') === _get(profileData, 'email'))
  }, [companyInfo, profileData])

  const levelIcon = useMemo(() => {
    const type = _get(companyInfo, 'supportInfo.support_level') || ''

    if (!type) return null
    if (type === 'Platinum') return <CertificatePlatinumBadgeIcon />
    if (type === 'Gold') return <CertificateGoldBadgeIcon />
    if (type === 'Silver') return <CertificateSilverBadgeIcon />
    if (type === 'Emerald') return <CertificateEmeraldBadgeIcon />
    return <CertificateUSPlatinumBadgeIcon />
  }, [companyInfo])

  const levelBorder = useMemo(() => {
    const type = _get(companyInfo, 'supportInfo.support_level') || ''

    if (!type) return '#F4F4F5'
    if (type === 'Platinum') return '#195DDB'
    if (type === 'Gold') return '#997208'
    if (type === 'Silver') return '#D2D4D6'
    return '#178594'
  }, [companyInfo])

  const handleSubscribe = async () => {
    try {
      const response = await subscribeCriticalUser({
        orgId: _get(profileData, 'currentOrganizationId') || '',
        subscribeFlg: !isSubscribeCritical
      }).unwrap()

      if (response?.success) {
        const successMessage = isSubscribeCritical
          ? translate('updateUnSubscribeSuccess')
          : translate('updateSubscribeSuccess')

        enqueueSnackbar(successMessage, {
          variant: 'success'
        })

        getOrganizationSupportInfo(_get(profileData, 'currentOrganizationId') || '')
      } else {
        enqueueSnackbar(translate('updateSubscribeFail'), {
          variant: 'error'
        })
      }
    } catch {
      enqueueSnackbar(translate('updateSubscribeFail'), {
        variant: 'error'
      })
    }
  }

  const supportInfoNotAvailable = useMemo(() => {
    return companyInfo?.supportInfo == null
  }, [companyInfo])

  const phoneSupportList = useMemo(() => {
    return _get(
      systemValueInfo.find((item: any) => item.key === SYSTEM_VALUE_KEYS.SUPPORT_SERVICES_PHONE),
      'data',
      []
    )
  }, [])

  return (
    <>
      {isLoading ? (
        <Skeleton animation="wave" variant="rectangular" width="100%" height="400px" />
      ) : (
        !supportInfoNotAvailable && (
          <Box
            sx={{
              backgroundColor: '#F4F4F5',
              p: 2,
              border: `2px solid ${levelBorder}`
            }}
          >
            <TemplateSection spacing={1.5}>
              <Box display="flex" alignItems="stretch" gap="10px" paddingRight={'8px'}>
                <Box display="flex" alignItems="center">
                  {levelIcon}
                </Box>
                <Box display="flex" flexDirection="column" flexWrap="wrap" flex={1}>
                  <Typography variant="h3" lineHeight="20px" color="#0B132A">
                    {_get(companyInfo, 'supportInfo.name') || '--'}
                  </Typography>
                  <Box
                    sx={{
                      '& > span': {
                        color: '#616875'
                      }
                    }}
                  >
                    <Typography component="span" variant="caption">
                      {'Support Account Number: '}
                    </Typography>
                    <Typography variant="caption" component="span" fontWeight={500}>
                      {_get(companyInfo, 'supportInfo.support_account_number') || ''}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {!_isEmpty(companyInfo?.supportUsers) && (
                <>
                  <Box>
                    <Divider />
                  </Box>

                  <Box>
                    {_map(_get(companyInfo, 'supportUsers'), (item, idx) => (
                      <React.Fragment key={idx.toString()}>
                        <InformationCard data={item} />
                      </React.Fragment>
                    ))}
                  </Box>
                </>
              )}

              <Box>
                <Typography
                  component="a"
                  href="https://www.opswat.com/support"
                  sx={{
                    color: '#1D6BFC',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '20px',
                    textAlign: 'left',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}
                >
                  Support Plans Information
                </Typography>
              </Box>

              <Box>
                <Divider />
              </Box>

              <Box>
                <Typography variant="body1" color="#616875" component="label">
                  Phone support
                </Typography>
              </Box>

              <Box>
                <PhoneSupportSection
                  supportLevel={_get(companyInfo, 'supportInfo.support_level') || ''}
                  isLoading={isLoading}
                  phoneSupportList={phoneSupportList}
                />
              </Box>

              <Box>
                <Divider />
              </Box>

              <Box>
                <Typography variant="body1" color="#616875" component="label">
                  Portal Administrators ({_size(_get(companyInfo, 'portalAdmins') || [])})
                </Typography>
              </Box>

              {_size(_get(companyInfo, 'portalAdmins') || []) > 0 && (
                <BoxAdvanced maxHeight="100px">
                  <Grid container spacing={1} sx={{ p: 0.25 }}>
                    {_map(_get(companyInfo, 'portalAdmins') || [], (item, idx) => (
                      <Grid item xs="auto" key={idx.toString()}>
                        <Tooltip placement="top" title={_get(item, 'email') || '--'} arrow>
                          <Button
                            disableElevation
                            variant="text"
                            sx={{
                              backgroundColor: '#E9EAEB',
                              color: '#485161',
                              fontSize: '13px',
                              lineHeight: '20px',
                              fontWeight: 400,
                              padding: '2px 8px',
                              minHeight: 'auto',
                              '&:hover': {
                                backgroundColor: '#d7d8d9'
                              }
                            }}
                          >
                            {_get(item, 'name') || '--'}
                          </Button>
                        </Tooltip>
                      </Grid>
                    ))}
                  </Grid>
                </BoxAdvanced>
              )}

              <Box>
                <Divider />
              </Box>

              {/* Case Access */}
              <OrganizationCaseAccess
                organizationCaseAccessInfo={_get(companyInfo, 'orgAccessCaseInfo')}
                isEditableCaseInfo={_get(companyInfo, 'isEditableCaseInfo')}
                disabled={!hasFullSupportServicePerm}
              />

              {/* Critical Alert Users */}
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body1" color="#616875" component="label">
                  Critical alerts notification list ({_size(_get(companyInfo, 'criticalAlertUsers') || [])})
                </Typography>
                <IconButton
                  onClick={() => navigate(`${myOrganizationPageURL}/critical-alert-users`)}
                  sx={{ padding: 0 }}
                >
                  <ChevronRightIcon />
                </IconButton>
              </Box>

              {_size(_get(companyInfo, 'criticalAlertUsers') || []) > 0 && (
                <BoxAdvanced maxHeight="150px">
                  <Grid container spacing={1} sx={{ p: 0.25 }}>
                    {_map(_get(companyInfo, 'criticalAlertUsers') || [], (item, idx) => (
                      <Grid item xs="auto" key={idx.toString()}>
                        <Tooltip placement="top" title={_get(item, 'name') || _get(item, 'email') || '--'} arrow>
                          <Button
                            disableElevation
                            variant="text"
                            sx={{
                              backgroundColor: '#E9EAEB',
                              color: '#485161',
                              fontSize: '13px',
                              lineHeight: '20px',
                              fontWeight: 400,
                              padding: '2px 8px',
                              minHeight: 'auto',
                              '&:hover': {
                                backgroundColor: '#d7d8d9'
                              }
                            }}
                          >
                            {_get(item, 'name') || _get(item, 'email') || '--'}
                          </Button>
                        </Tooltip>
                      </Grid>
                    ))}
                  </Grid>
                </BoxAdvanced>
              )}

              <Box sx={{ width: '100%' }}>
                <Button
                  disabled={!hasViewSupportServicePerm}
                  fullWidth
                  disableElevation
                  variant={isSubscribeCritical ? 'outlined' : 'contained'}
                  color={isSubscribeCritical ? 'inherit' : 'primary'}
                  onClick={() => handleSubscribe()}
                  sx={{
                    textTransform: 'none'
                  }}
                >
                  {isSubscribeCritical ? 'Unsubscribe from critical alerts' : 'Subscribe to critical alerts'}
                </Button>
              </Box>
            </TemplateSection>
          </Box>
        )
      )}
    </>
  )
}

export default UserInformation
