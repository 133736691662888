import _get from 'lodash/get'

import { enqueueSnackbar } from 'notistack'
import { CSSProperties, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useWatch } from 'react-hook-form'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { ActionButton, Box, Grid, Typography, TypographyDivider, TypographyLineClamp } from '@opswat/react-ui'

import {
  useLazyCustomerLicensedProductsQuery,
  usePinUnpinCustomerLicensedProductsMutation
} from 'myopswat-web/src/api/license'
import {
  useDownloadedProductAddMutation,
  useLazyBasicActivationServerProductsQuery,
  useLazyIcapIntegrationsQuery,
  useLazyProductQuery
} from 'myopswat-web/src/api/product'
import { IDownloadedProductInput } from 'myopswat-web/src/api/product/types'
import DialogDownloadVersion from 'myopswat-web/src/components/Dialog/DialogDownloadVersion'
import { PRODUCT_IDS } from 'myopswat-web/src/constants/product-ids'
import useGetPortalPermissions from 'myopswat-web/src/hooks/useGetPortalPermissions'
import useHasPermissions from 'myopswat-web/src/hooks/useHasPermissions'
import { homePageURL } from 'myopswat-web/src/routes'

import TooltipSubOrgLabel from '../../../MyLicensesPage/components/TooltipSubOrgLabel'
import { ALL_VALUE_OPTION } from '../../../MyLicensesPage/constants'
import NestedCustomerOrgTable from '../../components/NestedCustomerOrgTable'
import DialogCustomerLicenseHistory from '../../dialogs/DialogCustomerLicenseHistory'

import { PinIcon } from '@opswat/react-icon'
import CustomerContainerTitle from '../../components/CustomerContainerTitle'
import { CustomerLicensesContext, NO_PRODUCT_LICENSES_MESSAGE } from '../../interface'
import CustomerLicensedProductsFilter from './searchFilter'

interface IProps {
  forViewAllModal?: boolean
  nearCapacity?: boolean
  expiringDuration?: number
  disableFirstLoad?: boolean
}

const CustomerLicensedProductsSection: FC<IProps> = ({
  nearCapacity,
  forViewAllModal,
  expiringDuration,
  disableFirstLoad
}) => {
  const {
    controlProduct: control,
    setValueProduct: setValue,
    getValuesProduct: getValues,
    setCsvFilter,
    defaultProductFilters
  } = useContext(CustomerLicensesContext)

  const { permissions } = useGetPortalPermissions()

  const hasDownloadReportPermission = useHasPermissions({
    targetPerms: ['full_customer_organization_license'],
    userPerms: permissions
  })
  const [getCustomerLicenses, { data: customerLicenseData, isFetching: isFetchingCustomerLicenseData }] =
    useLazyCustomerLicensedProductsQuery()
  const [getPinnedCustomerLicenses, { data: pinnedLicenseData, isFetching: isFetchingPinnedLicenseData }] =
    useLazyCustomerLicensedProductsQuery()
  const [getProductOptions, { data: productOptions }] = useLazyBasicActivationServerProductsQuery()
  const [pinUnpinCustomerLicense] = usePinUnpinCustomerLicensedProductsMutation()
  const [getIcapIntegrations, { data: icapIntegrationsData }] = useLazyIcapIntegrationsQuery()
  const [getProduct, { data: productDetailData, isFetching: isFetchingProductDetail }] = useLazyProductQuery()
  const [downloadedProductAdd] = useDownloadedProductAddMutation()

  const customerOrgIds = useWatch({ control, name: 'customerOrgIds' })
  const searchFilters = useWatch({ control, name: 'filters' })

  const [firstLoad, setFirstLoad] = useState<boolean>(!disableFirstLoad)
  const [isMappingData, setIsMappingData] = useState<boolean>(true)
  const [mappedProductsData, setMappedProductsData] = useState<any[]>([])
  const [mappedPinnedData, setMappedPinnedData] = useState<any[]>([])
  const [hiddenProductsData, setHiddenProductsData] = useState<any>([])
  const [isFetchingPinned, setIsFetchingPinned] = useState<boolean>(true)
  const [totalPinned, setTotalPinned] = useState<number>(0)

  const [chosenItem, setChosenItem] = useState<any>()
  const [openProductDialog, setOpenProductDialog] = useState<boolean>(false)
  const [openHistoryDialog, setOpenHistoryDialog] = useState<boolean>(false)

  const handleCheckEmptyCustomer = useCallback((item: any) => {
    const products = _get(item, 'products') ?? []
    const children = _get(item, 'children') ?? []
    const checkEmptyChildren = children.every((child: any) => handleCheckEmptyCustomer(child))
    return products.length === 0 && checkEmptyChildren
  }, [])

  const handleCheckEmptyData = useCallback((data: any) => {
    const orgData = _get(data, 'orgs') ?? []
    return orgData.every((item: any) => handleCheckEmptyCustomer(item))
  }, [])

  const handleMapChildrenData = useCallback((item: any, index: number) => {
    const products = _get(item, 'products') ?? []
    const subOrgName = `SUB-ORGANIZATION ${index + 1}`
    if (products.length === 0) {
      return { name: item.name, subOrgName, activation_key: NO_PRODUCT_LICENSES_MESSAGE }
    }
    return {
      ...item,
      level: 1,
      subOrgName,
      products: products.map((product: any) => ({
        ...product,
        is_license: true,
        licenses: [...product.licenses].map((license: any) => ({
          ...license,
          is_license: true
        }))
      }))
    }
  }, [])

  const handleMapLicense = useCallback(
    (item: any): any => {
      const products: any[] = _get(item, 'products') ?? []
      const children: any[] = _get(item, 'children') ?? []
      const isCustomerParent = item.children.length > 0
      const isChosenOrg = customerOrgIds.some((org: any) => org.value === item.id)
      const parentHasLicense = item.products && isCustomerParent
      const parentNoLicense = products.length === 0 && !isCustomerParent

      const mappedProducts = products.map((product: any) => ({
        ...product,
        is_license: true,
        licenses: [...product.licenses].map((license: any) => ({
          ...license,
          is_parent_org: parentHasLicense,
          is_license: true
        }))
      }))
      const mappedChildren = children.map(handleMapChildrenData)
      const mappedParentCustomer = {
        ...item,
        level: 1,
        products: mappedProducts,
        children: [],
        is_license: false,
        activation_key: products.length === 0 ? NO_PRODUCT_LICENSES_MESSAGE : ''
      }

      return {
        ...item,
        level: 0,
        isCustomerParent,
        products: [],
        children: isChosenOrg && !parentNoLicense ? [mappedParentCustomer, ...mappedChildren] : mappedChildren,
        activation_key: parentNoLicense ? NO_PRODUCT_LICENSES_MESSAGE : ''
      }
    },
    [customerOrgIds]
  )

  const handleGetData = useCallback(
    async (filters: any) => {
      setIsMappingData(true)
      const _filters = forViewAllModal ? defaultProductFilters : filters
      await getCustomerLicenses({
        pinnedOnly: false,
        customerOrgIds: customerOrgIds.map((item: any) => item.value),
        filters: {
          ..._filters,
          licenseType: _filters.licenseType === ALL_VALUE_OPTION ? '' : _filters.licenseType,
          firstLoad,
          nearCapacity: nearCapacity,
          expiringDuration: expiringDuration
        }
      })
        .unwrap()
        .then((response: any) => {
          const orgData = _get(response, 'orgs') ?? []
          setFirstLoad(false)
          setMappedProductsData(orgData.map(handleMapLicense))
          setHiddenProductsData(_get(response, 'hiddenKeys', []))
          setIsMappingData(false)
        })
        .catch((error: any) => {
          console.error(error)
          setIsMappingData(false)
        })
    },
    [firstLoad, customerOrgIds]
  )

  const handleGetPinnedData = useCallback(async () => {
    setIsFetchingPinned(true)
    await getPinnedCustomerLicenses({
      pinnedOnly: true,
      customerOrgIds: customerOrgIds.map((item: any) => item.value),
      filters: {
        q: '',
        productIds: [],
        licenseType: ALL_VALUE_OPTION,
        licenseStatuses: [],
        nearCapacity: false
      }
    })
      .unwrap()
      .then((response: any) => {
        setIsFetchingPinned(false)
        const orgData = _get(response, 'orgs') ?? []
        const totalKeys = _get(response, 'totalKeys') ?? 0
        setMappedPinnedData(orgData.map(handleMapLicense))
        setTotalPinned(totalKeys)
      })
      .catch((error: any) => {
        setIsFetchingPinned(false)
        console.error(error)
      })
  }, [customerOrgIds])

  const handlePinLicense = useCallback(async (data: any, isPinned?: boolean) => {
    await pinUnpinCustomerLicense({ keyId: data.id, pin: isPinned ?? true })
      .unwrap()
      .then((response: any) => {
        if (response.success) {
          enqueueSnackbar(`${isPinned ? 'Pinned' : 'Unpinned'} license successfully`, {
            variant: 'success'
          })
          handleGetPinnedData()
        }
      })
      .catch(() => {
        enqueueSnackbar(
          `${isPinned ? 'Pinning' : 'Unpinning'} license has failed. Please give the system a moment then try again.`,
          {
            variant: 'error'
          }
        )
      })
  }, [])

  const handleDownloadedProductAdd = useCallback(async (data: IDownloadedProductInput, isICAP?: boolean) => {
    try {
      await downloadedProductAdd(data).unwrap()
      if (isICAP) {
        getIcapIntegrations()
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  const handleDownloadProduct = useCallback((item: any) => {
    if (_get(item, 'slug', '')) {
      getProduct(_get(item, 'slug', ''))
      if (_get(item, 'id') === PRODUCT_IDS.MD_ICAP) {
        getIcapIntegrations()
      }
    }
    setChosenItem(item)
    setOpenProductDialog(true)
  }, [])

  const handleDownloadCSV = useCallback(
    (data: any) => {
      const params = {
        id: data.id,
        key: data.activation_key
      }
      setCsvFilter?.(params)
    },
    [setCsvFilter]
  )

  const handleViewHistory = useCallback((item: any) => {
    setChosenItem(item)
    setOpenHistoryDialog(true)
  }, [])

  const renderCell = useCallback((data: any, key: string, sx?: CSSProperties) => {
    const value = data[key]
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
        tooltipValue={value}
        sx={sx}
      >
        {value || ''}
      </TypographyLineClamp>
    )
  }, [])

  const renderCellNoEllipsis = useCallback((data: any, key: string, sx?: CSSProperties) => {
    const value = data[key]
    return <Typography variant="body2">{value}</Typography>
  }, [])

  const renderDateCell = useCallback((data: any, key: string) => {
    const value = formatDatetime(data[key], DATE_FORMATS.DATE)
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
      >
        {value !== '--' && value}
      </TypographyLineClamp>
    )
  }, [])

  const renderOrgNameCell = useCallback((data: any, sx?: CSSProperties) => {
    const orgName = _get(data, 'name')
    const subOrgName = _get(data, 'subOrgName')
    const level = _get(data, 'level')
    let color = '#3D4A68'

    if (level === 0) {
      // godfather organizations
      color = '#154FBA'
    } else if (_get(data, 'slug') && !_get(data, 'is_child')) {
      // product parent
      color = '#1D6DFC'
    }

    return <TooltipSubOrgLabel mainLabel={subOrgName} subLabel={orgName} sx={{ ...sx, color }} />
  }, [])

  const renderExpireDateCell = useCallback((data: any) => {
    const date = _get(data, 'expired_date', '')
    const daysToExpire = _get(data, 'days_to_expire', 0)
    if (!date) return ''
    const renderDate = formatDatetime(date, DATE_FORMATS.DATE)
    const renderExpireDate = ` - ${daysToExpire} days`

    if (renderDate.includes('2100'))
      return (
        <TypographyLineClamp
          line={1}
          variant="body2"
          tooltipValue={'Perpetual'}
          tooltipPlacement="top"
          tooltipProps={{
            tooltip: {
              sx: {
                color: '#1B273C',
                backgroundColor: '#E9EAEB',
                whiteSpace: 'pre-line'
              }
            },
            arrow: {
              sx: {
                color: '#E9EAEB'
              }
            }
          }}
        >
          Perpetual
        </TypographyLineClamp>
      )
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={`${renderDate} ${daysToExpire > 0 ? renderExpireDate : ''}`}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
      >
        {renderDate}
        {daysToExpire > 0 ? ` - ${daysToExpire} days` : ''}
      </TypographyLineClamp>
    )
  }, [])

  const renderStatusCell = useCallback((data: any) => {
    const value = _get(data, 'status', '')
    const isEvaluation = _get(data, 'is_evaluation', false)
    const daysToExpire = _get(data, 'days_to_expire', 0)

    let color = '#008A00'
    if (value === 'expired') {
      color = '#D00300'
    }
    if (value === 'expiring') {
      if (isEvaluation) {
        color = '#D00300'
      } else if (daysToExpire < 90 && daysToExpire >= 30) {
        color = '#FDBD0D'
      } else {
        color = '#D00300'
      }
    }

    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
        textTransform="capitalize"
        style={{ color }}
      >
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const renderTypeCell = useCallback((data: any, key: string) => {
    const value = data[key]
    if (value === undefined) {
      return
    }
    const type = value ? 'Evaluation' : 'Purchased'
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={type}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
      >
        {type}
      </TypographyLineClamp>
    )
  }, [])

  const renderDeployedCell = useCallback((data: any, deployedKey: string, purchasedKey: string) => {
    const deployed = data[deployedKey]
    const purchased = data[purchasedKey]
    const value = deployed !== undefined && purchased !== undefined ? `${deployed}/${purchased}` : ''

    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
      >
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const renderActionCell = useCallback(
    (data: any) => {
      if (forViewAllModal) {
        return <></>
      }

      const licenses = _get(data, 'licenses') ?? []
      const children = _get(data, 'children') ?? []
      const status = _get(data, 'status', '')

      const isChild = _get(data, 'is_child') ?? false // second level product
      const isParent = children.length > 0 || licenses.length > 0 // first level product
      const isExpired = status === 'expired'

      const parentItems = [
        {
          label: 'Download Product Installer',
          onClick: () => handleDownloadProduct(data)
        }
      ]

      const childItems = [
        {
          label: 'Pin',
          onClick: () => {
            handlePinLicense(data, true)
          }
        },
        {
          label: 'See full License History',
          onClick: () => handleViewHistory(data)
        }
      ]

      if (!isExpired && hasDownloadReportPermission) {
        childItems.push({
          label: 'Download Active Deployment Report',
          onClick: () => handleDownloadCSV(data)
        })
      }

      return (
        !isChild && (
          <ActionButton
            items={isParent ? parentItems : childItems}
            paperStyles={{
              minWidth: '150px'
            }}
            buttonStyles={{
              padding: '0px'
            }}
          />
        )
      )
    },
    [hasDownloadReportPermission]
  )

  const renderPinActionCell = useCallback(
    (data: any) => {
      if (forViewAllModal) {
        return <></>
      }

      const licenses = _get(data, 'licenses') ?? []
      const children = _get(data, 'children') ?? []
      const status = _get(data, 'status', '')

      const isChild = _get(data, 'is_child') ?? false // second level product
      const isParent = children.length > 0 || licenses.length > 0 // first level product
      const isExpired = status === 'expired'

      const parentItems = [
        {
          label: 'Download Product Installer',
          onClick: () => handleDownloadProduct(data)
        }
      ]

      const childItems = [
        {
          label: 'Unpin',
          onClick: () => {
            handlePinLicense(data, false)
          }
        },
        {
          label: 'See full License History',
          onClick: () => handleViewHistory(data)
        }
      ]

      if (!isExpired && hasDownloadReportPermission) {
        childItems.push({
          label: 'Download Active Deployment Report',
          onClick: () => handleDownloadCSV(data)
        })
      }

      return (
        !isChild && (
          <ActionButton
            items={isParent ? parentItems : childItems}
            paperStyles={{
              minWidth: '150px'
            }}
            buttonStyles={{
              padding: '0px'
            }}
          />
        )
      )
    },
    [forViewAllModal, hasDownloadReportPermission]
  )

  const productColumns = useMemo(() => {
    const defaultColumns = [
      {
        header: 'Product Name',
        style: { minWidth: '20vw', width: '20vw' },
        body: (data: any) =>
          renderOrgNameCell(data, {
            color: _get(data, 'slug') && !_get(data, 'is_child') ? '#1d6dfc' : undefined,
            fontWeight: _get(data, 'is_license') ? 400 : 500
          }),
        highlightLevels: [0, 1],

        linkLevels: (data: any) => {
          const link = _get(data, 'slug') && !_get(data, 'is_child') ? `${homePageURL}/${_get(data, 'slug')}` : ''
          return ['', link, link, link]
        }
      },
      {
        header: 'License Key',
        style: { minWidth: '18vw', width: '18vw' },
        body: (data: any) => renderCellNoEllipsis(data, 'activation_key')
      },
      {
        header: 'Status',
        style: {
          minWidth: '5vw',
          width: '5vw',
          textAlign: 'center',
          sx: {
            justifyContent: 'center'
          }
        },
        body: renderStatusCell,
        hiddenLevels: [0]
      },
      {
        header: 'Start Date',
        style: { minWidth: '6vw', width: '6vw' },
        body: (data: any) => renderDateCell(data, 'activated_date'),
        hiddenLevels: [0]
      },
      {
        header: 'Expiration Date',
        style: { minWidth: '10vw', width: '10vw' },
        body: renderExpireDateCell,
        hiddenLevels: [0]
      },
      {
        header: 'Deployed/ Purchased',
        style: {
          minWidth: '5vw',
          width: '5vw',
          textAlign: 'center',
          sx: {
            justifyContent: 'center'
          }
        },
        body: (data: any) => renderDeployedCell(data, 'deployed_quantity', 'purchased_quantity'),
        hiddenLevels: [0]
      },
      {
        header: 'Type',
        style: { minWidth: '5vw', width: '5vw' },
        body: (data: any) => renderTypeCell(data, 'is_evaluation'),
        hiddenLevels: [0]
      },
      {
        header: 'Note',
        style: { minWidth: '9vw', width: '9vw' },
        body: (data: any) => renderCell(data, 'note'),
        hiddenLevels: [0]
      },
      {
        header: '',
        style: { minWidth: 40, textAlign: 'right' },
        body: renderActionCell,
        hiddenLevels: [0, 1]
      }
    ]

    if (forViewAllModal) {
      return defaultColumns.filter(c => c.header != 'Status')
    } else return defaultColumns
  }, [forViewAllModal])

  const pinnedColumns = useMemo(() => {
    const columns = [...productColumns]
    columns.pop()
    return [
      ...columns,
      {
        header: '',
        style: { minWidth: 40, textAlign: 'right' },
        body: renderPinActionCell,
        hiddenLevels: [0, 1]
      }
    ]
  }, [productColumns])

  const TableMemo = useMemo(
    () => (
      <NestedCustomerOrgTable
        defaultExpandedAll
        isLoading={isFetchingCustomerLicenseData || isMappingData}
        columns={productColumns}
        data={handleCheckEmptyData(customerLicenseData) ? [] : mappedProductsData}
        childrenKeys={[
          ['products', 'children'],
          ['products', 'licenses', 'children'],
          ['licenses', 'children'],
          ['licenses'],
          ['']
        ]}
        noDataText={NO_PRODUCT_LICENSES_MESSAGE}
      />
    ),
    [productColumns, isFetchingCustomerLicenseData, customerLicenseData, mappedProductsData, isMappingData]
  )

  const PinnedTableMemo = useMemo(
    () => (
      <NestedCustomerOrgTable
        noHeader
        defaultExpandedAll
        isLoading={isFetchingPinned || isFetchingPinnedLicenseData}
        columns={pinnedColumns}
        data={handleCheckEmptyData(pinnedLicenseData) ? [] : mappedPinnedData}
        childrenKeys={[
          ['products', 'children'],
          ['products', 'licenses', 'children'],
          ['licenses', 'children'],
          ['licenses'],
          ['']
        ]}
        noDataText={NO_PRODUCT_LICENSES_MESSAGE}
      />
    ),
    [mappedPinnedData, isFetchingPinned, pinnedLicenseData, pinnedColumns]
  )

  useEffect(() => {
    getProductOptions()
  }, [])

  useEffect(() => {
    handleGetData(searchFilters)
    !forViewAllModal && handleGetPinnedData()
  }, [searchFilters])

  return (
    <Grid item container xs={12} id="customer-licensed-products-section">
      <Grid item container xs={12} sx={{ border: '1px solid #BCBFC3' }}>
        <Grid item xs={12}>
          <CustomerContainerTitle title={'Products'} />
        </Grid>

        {!forViewAllModal && (
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: '15px' }}>
            <TypographyDivider
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, height: '20px' }}>
                  <PinIcon size={16} />
                  <Typography variant="body1" marginTop="2px">
                    Pinned ({totalPinned})
                  </Typography>
                </Box>
              }
              propsTypo={{
                component: 'label',
                variant: 'body1'
              }}
            />
            {PinnedTableMemo}
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            padding: '0px 15px'
          }}
        >
          {!forViewAllModal && (
            <CustomerLicensedProductsFilter
              productOptions={productOptions}
              currentFilters={searchFilters}
              setCurrentFilters={(data: any) => {
                setValue('filters', data)
              }}
              handleSearchKeyword={(keyword: string) => {
                setValue('filters', { ...getValues('filters'), q: keyword.trim() })
              }}
            />
          )}
          {TableMemo}
        </Grid>
      </Grid>

      {chosenItem && (
        <DialogDownloadVersion
          icapIntegrationsData={icapIntegrationsData}
          productDetailData={productDetailData}
          isOpenPVersion={openProductDialog}
          setIsOpenPVersion={setOpenProductDialog}
          handleDownloadedProductAdd={handleDownloadedProductAdd}
          isFetchingProductDetail={isFetchingProductDetail}
        />
      )}

      <DialogCustomerLicenseHistory
        orgId={_get(chosenItem, 'organization_id')}
        productId={_get(chosenItem, 'product_id')}
        isParent={_get(chosenItem, 'is_parent_org')}
        hiddenData={hiddenProductsData}
        openHistoryDialog={openHistoryDialog}
        onCloseHistoryDialog={() => {
          setOpenHistoryDialog(false)
          setChosenItem(undefined)
        }}
      />
    </Grid>
  )
}

export default CustomerLicensedProductsSection
