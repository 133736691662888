import React, { FC, useState } from 'react'

import _debounce from 'lodash/debounce'

import { copyText } from '@opswat/react-core'
import { CopySimpleIcon } from '@opswat/react-icon'
import { Box, Tooltip, Typography } from '@opswat/react-ui'

interface IProps {
  item: any
}

const BoxPhone: FC<IProps> = ({ item }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false)

  const handleReturnCopy = _debounce(() => {
    setIsCopied(false)
  }, 1000)

  const handleCopy = () => {
    copyText(item.value)
    setIsCopied(true)
    handleReturnCopy()
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          height: '35px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          padding: '4px 12px 4px 4px',
          backgroundColor: '#E9EAEB',
          borderLeft: '4px solid #d7d8d9'
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography sx={{ ml: '20px', fontWeight: 500 }}>{item.label}</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography color="textSecondary" variant="subtitle2" sx={{ mr: 1 }}>
            {item.value}
          </Typography>
          <Tooltip title={isCopied ? 'Copied' : 'Copy'} placement="top" arrow onClick={handleCopy}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <CopySimpleIcon size={16} />
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default BoxPhone
