import { FC, useState } from 'react'

import { CloseIcon } from '@opswat/react-icon'
import { Box, DialogAdvanced, Typography } from '@opswat/react-ui'
import StackBarChart from 'myopswat-web/src/components/CustomCharts/StackBarChart'
import CustomerLicenseDetailSection from '../../sections/LicensedProductsSection'

interface IProps {
  nearCapacityData: any
}

const NearCapacityLicensesChart: FC<IProps> = ({ nearCapacityData }) => {
  const renderNearCapacityContainerBody = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography fontSize="24px" lineHeight="28px" color="#1B273C" fontWeight={400}>
          {nearCapacityData?.total_count || 0} Licenses
        </Typography>
        <Typography variant="caption" color="#1B273C" sx={{ display: 'flex', alignItems: 'center', height: 30 }}>
          Peaks are identified when license slot usage exceeds more than 80%.
        </Typography>
      </Box>
    )
  }

  const [open, setOpen] = useState(false)

  return (
    <>
      <StackBarChart
        data={nearCapacityData.data}
        containerTitle="NEAR-CAPACITY ON-PREM ACTIVE LICENSES"
        containerSx={{ maxWidth: 500, display: 'flex', flexDirection: 'column', gap: 6 }}
        chartHeight={10}
        maxCategory={4}
        onRenderContainerBody={renderNearCapacityContainerBody}
        onClickActionBtn={() => setOpen(true)}
        hint="Hover over each color bar to see Organization & Sub-Organization that has near-cap licenses."
      />
      <DialogAdvanced
        open={open}
        onClose={() => setOpen(false)}
        iconClose={<CloseIcon />}
        dialogProps={{
          maxWidth: 'xxl'
        }}
        title={`${nearCapacityData?.total_count} Near-capacity Licenses`}
        content={<CustomerLicenseDetailSection forViewAllModal nearCapacity />}
      />
    </>
  )
}

export default NearCapacityLicensesChart
