/** ----------------- PATHNAME -------------------- */
export const evaluatedLicensesPath = 'evaluated-licenses'
export const purchasedLicensesPath = 'purchased-licenses'
export const cloudBasedLicensesPath = 'cloud-based-products-licenses'

/** ----------------- ROUTE -------------------- */
export const licensedProductsPageURL = '/my-licenses'
export const licensedProductsCustomerPageURL = '/customer-licenses'

export const iframeLicensedProductsPageURL = '/iframe-pages/licensed-products'
export const iframeLicensedProductsFlexPageURL = `${iframeLicensedProductsPageURL}/:tabPath`
