import { FC, useCallback, useEffect, useMemo } from 'react'

import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _includes from 'lodash/includes'

import { useModal } from '@ebay/nice-modal-react'
import { Link, Outlet, useLocation } from 'react-router-dom'

import {
  CloudIcon,
  DashboardEngineIcon,
  DownloadIcon,
  HomeIcon,
  OrganizationsIcon,
  PoliciesIcon,
  ServiceSupportIcon,
  SolidRightIcon,
  UserGroupIcon
} from '@opswat/react-icon'
import { AppMenu, Box, LeftMenu, LeftMenuBanner, TemplateDashboard } from '@opswat/react-ui'

import {
  selectPopupMessage,
  setDialogMessage,
  toggleDialogs
} from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import {
  homePageURL,
  licensedProductsCustomerPageURL,
  licensedProductsPageURL,
  myOrganizationCriticalAlertUsersPageURL,
  myOrganizationEventHistoryPageURL,
  myOrganizationGeneralInfoPageURL,
  myOrganizationPageURL,
  myOrganizationRolesPageURL,
  myOrganizationSecurityPageURL,
  myOrganizationUsersPageURL,
  productDownloadsPageURL
} from 'myopswat-web/src/routes'

import MessageModal from 'myopswat-web/src/components/Dialog/MessageModal'
import { supportServicesPageURL } from 'myopswat-web/src/routes/supportServicesRoutes'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'

import BannerHeader from 'myopswat-web/src/components/Banner/BannerHeader'
import { isEnabledBanner, USER_ACTIVITY } from 'myopswat-web/src/constants'
import { useLazyCheckShowDialogQuery } from 'myopswat-web/src/api/account'
import DialogSystemNotification from 'myopswat-web/src/components/Dialog/DialogSystemNotification'
import { DIALOGS_WEB } from 'myopswat-web/src/constants/dialogs'
import useUserActivityHook from 'myopswat-web/src/hooks/useUserActivityHook'

import useHasPermissions from '../../hooks/useHasPermissions'
import AllForm from './AllForm'
import LogoutLayout from './LogoutLayout'
import RightMenu from './RightMenu'

interface IProps {
  type?: string
}

const LayoutPage: FC<IProps> = ({ type }) => {
  const location = useLocation()

  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)
  const userTypes: string[] = _get(profileData, 'userTypes', [])
  const mdEmailSecurityUrl: string = _get(profileData, 'mdEmailSecurityUrl', '')
  const isChannelPartner = userTypes.includes('CP')
  const message = useTypedSelector(selectPopupMessage)
  const { createUserActivity } = useUserActivityHook()

  const dispatch = useAppDispatch()

  const messageModal = useModal(MessageModal)

  const handleSelectMyOrgPage = () => {
    const defaultPageURL = myOrganizationGeneralInfoPageURL
    const userPortalPermissions = _get(profileData, 'portalPermissions', [])
    const hasViewMyOrgPerm = useHasPermissions({
      targetPerms: ['view_my_organizations', 'full_my_organizations'],
      userPerms: userPortalPermissions
    })
    const hasViewOrgUsersPerm = useHasPermissions({
      targetPerms: ['view_my_organizations_users', 'full_my_organizations_users'],
      userPerms: userPortalPermissions
    })
    const hasViewOrgRolesPerm = useHasPermissions({
      targetPerms: ['view_my_organizations_role', 'full_my_organizations_role'],
      userPerms: userPortalPermissions
    })
    const hasViewOrgSecurityPerm = useHasPermissions({
      targetPerms: ['view_my_organizations_security', 'full_my_organizations_security'],
      userPerms: userPortalPermissions
    })
    const hasViewOrgEventHistoryPerm = useHasPermissions({
      targetPerms: ['view_my_organizations_eventhistory', 'full_my_organizations_eventhistory'],
      userPerms: userPortalPermissions
    })
    const hasViewOrgCritiCalAlertPerm = useHasPermissions({
      targetPerms: ['view_my_organizations_criticalalert', 'full_my_organizations_criticalalert'],
      userPerms: userPortalPermissions
    })
    if (hasViewMyOrgPerm) {
      return defaultPageURL
    } else if (hasViewOrgUsersPerm) {
      return myOrganizationUsersPageURL
    } else if (hasViewOrgRolesPerm) {
      return myOrganizationRolesPageURL
    } else if (hasViewOrgSecurityPerm) {
      return myOrganizationSecurityPageURL
    } else if (hasViewOrgEventHistoryPerm) {
      return myOrganizationEventHistoryPageURL
    } else if (hasViewOrgCritiCalAlertPerm) {
      return myOrganizationCriticalAlertUsersPageURL
    }
    return defaultPageURL
  }

  const [checkShowDialog] = useLazyCheckShowDialogQuery()
  const cloudServices = useMemo(() => {
    const maUrlsData: any = _get(profileData, 'maUrls')
    const maServices = []
    if (maUrlsData) {
      const maUrls = maUrlsData.urls
      if (maUrls.length == 1) {
        maServices.push({
          label: maUrlsData.defaultText,
          propListItem: {
            component: Link,
            to: maUrls[0].url
          }
        })
      } else {
        const children = maUrls.map((item: any) => ({
          label: item.text,
          propListItem: {
            component: Link,
            to: item.url
          }
        }))
        maServices.push({
          label: maUrlsData.defaultText,
          expandIcon: <SolidRightIcon />,
          children: children
        })
      }
    }

    return [
      {
        label: 'Cloud Services',
        icon: <CloudIcon />,
        expandIcon: <SolidRightIcon />,
        isHiding: false,
        children: [
          {
            label: 'MetaDefender Cloud',
            propListItem: {
              component: Link,
              to: 'https://metadefender.opswat.com/login'
            }
          },
          ...maServices,
          {
            label: 'Filescan.io',
            propListItem: {
              component: Link,
              to: 'https://filescan.io'
            }
          },
          {
            label: 'Cloud Email Security',
            propListItem: {
              component: Link,
              to: mdEmailSecurityUrl ?? 'https://www.opswat.com/solutions/email-security/cloud-email-gateway'
            }
          }
        ]
      }
    ]
  }, [profileData])

  const handleSelectPage = (data: string) => {
    if (!data) return false
    if (_includes(location.pathname, `${data}`)) return true
    return false
  }

  const handleRedirectAllbound = useCallback(() => {
    // Record user history
    createUserActivity(USER_ACTIVITY.redirect, { system: 'Allbound' })
    window.open(process.env.REACT_APP_OPSWAT_ALLBOUND_URL, '_blank')
  }, [])

  const handleOnClickOCM = useCallback(async () => {
    await checkShowDialog('ocm_no_org_message').then((response: any) => {
      if (response.data) {
        dispatch(
          toggleDialogs({
            [DIALOGS_WEB.OCM_NO_ORGANIZATION]: true
          })
        )
      } else {
        window.location.href = process.env.REACT_APP_OPSWAT_OC_URL || ''
      }
    })
  }, [])

  const arrayData = [
    {
      label: 'Home',
      path: homePageURL,
      icon: <HomeIcon />,
      propListItem: {
        selected: handleSelectPage(homePageURL),
        component: Link,
        to: homePageURL
      }
    },
    {
      label: 'Central Management',
      icon: <DashboardEngineIcon />,
      expandIcon: <SolidRightIcon />,
      propListItem: {
        selected: false,
        onClick: () => {
          handleOnClickOCM()
        }
      }
    },
    ...cloudServices,
    {
      label: 'Product Downloads',
      path: productDownloadsPageURL,
      icon: <DownloadIcon />,
      propListItem: {
        selected: handleSelectPage(productDownloadsPageURL),
        component: Link,
        to: productDownloadsPageURL
      }
    },
    {
      label: 'My Licenses',
      path: licensedProductsPageURL,
      icon: <PoliciesIcon />,
      propListItem: {
        selected: handleSelectPage(licensedProductsPageURL) || handleSelectPage(licensedProductsCustomerPageURL),
        component: Link,
        to: licensedProductsPageURL
      }
    },
    ...(_get(profileData, 'currentOrganizationId') || _get(profileData, 'usingOrganizationId')
      ? [
          {
            label: 'My Organization',
            path: myOrganizationGeneralInfoPageURL,
            icon: <OrganizationsIcon />,
            propListItem: {
              selected: handleSelectPage(myOrganizationPageURL),
              component: Link,
              to: handleSelectMyOrgPage()
            }
          }
        ]
      : []),
    {
      label: 'Support',
      path: supportServicesPageURL,
      icon: <ServiceSupportIcon />,
      propListItem: {
        selected: handleSelectPage(supportServicesPageURL),
        component: Link,
        to: supportServicesPageURL
      }
    },
    {
      isHiding: !isChannelPartner,
      label: 'OPSWAT Partner Portal',
      icon: <UserGroupIcon />,
      propListItem: {
        selected: false,
        component: Link,
        onClick: handleRedirectAllbound
      }
    }
  ]

  const menuList = useMemo(() => _filter(arrayData, item => !item?.isHiding), [arrayData])

  const handleRenderLayout = () => {
    if (isEnabledBanner)
      return (
        <TemplateDashboard
          top={
            <>
              <Box
                sx={{
                  position: 'fixed',
                  zIndex: 1201,
                  top: 0,
                  p: 0,
                  width: '100%'
                }}
              >
                <BannerHeader />
              </Box>

              <AppMenu
                sx={{
                  top: 50
                }}
              >
                <RightMenu />
              </AppMenu>
            </>
          }
          left={<LeftMenuBanner isLogo isIconCollapse menuList={menuList} content={<Outlet />} />}
        />
      )

    return (
      <TemplateDashboard
        top={
          <AppMenu>
            <RightMenu />
          </AppMenu>
        }
        left={<LeftMenu isLogo isIconCollapse menuList={menuList} content={<Outlet />} />}
      />
    )
  }

  const handleRenderFormLayout = () => {
    if (type === 'LOGOUT') {
      return <LogoutLayout />
    } else {
      return <AllForm />
    }
  }

  useEffect(() => {
    if (message) {
      messageModal.show({ message: message })
      dispatch(setDialogMessage(''))
    }
  }, [message])

  const systemNotification = useMemo(() => {
    return _get(profileData, 'systemNotification')
  }, [profileData])

  return (
    <>
      {handleRenderLayout()}
      {handleRenderFormLayout()}
      {systemNotification != null && <DialogSystemNotification data={systemNotification} />}
    </>
  )
}

export default LayoutPage
