/* eslint-disable no-var */
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import _get from 'lodash/get'

import { BLOB_FILE_TYPES, DIALOGS, handleCreateFile } from '@myopswat/common'
import { Box, ButtonLoading, TabAdvanced, Typography, TypographyDivider } from '@opswat/react-ui'

import {
  useCheckHasCustomerQuery,
  useCustomerLicenseKeyReportQuery,
  useOrganizationLicenseKeyReportQuery
} from 'myopswat-web/src/api/license'
import CustomerLicensesProvider from 'myopswat-web/src/pages/LicensedProductsPage/CustomerLicensesPage/context'
import MyLicensesPage from 'myopswat-web/src/pages/LicensedProductsPage/MyLicensesPage'
import { licensedProductsCustomerPageURL, licensedProductsPageURL } from 'myopswat-web/src/routes'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'

import { selectDialogs, toggleDialogs } from '../LayoutContainer/layoutContainerSlice'

const LicensedProductsContainer = () => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const dialogType = useTypedSelector(selectDialogs)
  const openLicenseDialog = _get(dialogType, DIALOGS.ACTIVE_LICENSES, false)

  const [csvFilter, setCsvFilter] = useState<any>()
  const [csvFilterCustomer, setCsvFilterCustomer] = useState<any>()
  const [activateSuccessful, setActivateSuccessful] = useState<boolean>(false)

  const { data: hasCustomer, isFetching: isFetchingHasCustomer } = useCheckHasCustomerQuery()
  const {
    data: deploymentIdsReportData,
    refetch: refetchDeploymentIdsReport,
    error: deploymentIdsReportError
  } = useOrganizationLicenseKeyReportQuery(csvFilter, {
    skip: !csvFilter
  })
  const {
    data: customerDeploymentIdsReportData,
    refetch: customerRefetchDeploymentIdsReport,
    error: customerDeploymentIdsReportError
  } = useCustomerLicenseKeyReportQuery(csvFilterCustomer, {
    skip: !csvFilterCustomer
  })

  const tabPaths = [licensedProductsPageURL, licensedProductsCustomerPageURL]

  const tabArray = useMemo(() => {
    const tabs = [
      {
        label: 'My Organization',
        content: <MyLicensesPage setCsvFilter={setCsvFilter} activateSuccessful={activateSuccessful} />,
        tabPath: licensedProductsPageURL,
        requiredPermissions: []
      }
    ]
    if (hasCustomer) {
      tabs.push({
        label: 'Customer Organizations',
        content: (
          <CustomerLicensesProvider
            setCsvFilter={setCsvFilterCustomer}
            activateSuccessful={activateSuccessful}
            permissions={{}}
          />
        ),
        tabPath: licensedProductsCustomerPageURL,
        requiredPermissions: []
      })
    }
    return tabs
  }, [setCsvFilter, setCsvFilterCustomer, activateSuccessful, hasCustomer])

  const handleOpenActivateLicenseDialog = useCallback(() => {
    dispatch(
      toggleDialogs({
        [DIALOGS.ACTIVE_LICENSES]: true
      })
    )
  }, [dispatch])

  const handleRenderTabs = useCallback(() => {
    if (isFetchingHasCustomer) {
      return <></>
    }
    if (!hasCustomer && window.location.pathname.includes('customer')) {
      return (
        <Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'left', textAlign: 'left' }}>
          <TypographyDivider label="Permissions Denied" />
          <Typography variant="body2" pt={5}>
            You do not have the permission to access this page. Your permissions might be modified by an administrator.
          </Typography>
          <Typography variant="body2" pt={2}>
            Please contact your administrators for help.
          </Typography>
        </Box>
      )
    }
    return (
      <>
        <TypographyDivider label="License Management" isDivider={false} />
        <TabAdvanced
          defaultTab={tabPaths.indexOf(window.location.pathname) || 0}
          borderBottom={'1px solid #E9EAEB'}
          tabs={tabArray}
          actions={
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                disableElevation: true,
                onClick: handleOpenActivateLicenseDialog,
                sx: {
                  marginBottom: '8px'
                }
              }}
              propsLoading={{ color: 'inherit' }}
            >
              Activate License
            </ButtonLoading>
          }
        />
      </>
    )
  }, [hasCustomer, isFetchingHasCustomer, handleOpenActivateLicenseDialog, tabArray])

  useEffect(() => {
    setActivateSuccessful(!openLicenseDialog)
  }, [openLicenseDialog])

  useEffect(() => {
    if (_get(csvFilter, 'id')) {
      refetchDeploymentIdsReport()
    }
  }, [csvFilter])

  useEffect(() => {
    if (_get(csvFilterCustomer, 'id')) {
      customerRefetchDeploymentIdsReport()
    }
  }, [csvFilterCustomer])

  useEffect(() => {
    if (_get(deploymentIdsReportData, 'content') && _get(csvFilter, 'key', '--')) {
      enqueueSnackbar(t('downloadLicensesCSVSuccess'), {
        variant: 'success'
      })

      handleCreateFile(_get(csvFilter, 'key', '--'), 'csv', _get(deploymentIdsReportData, 'content', ''), {
        type: BLOB_FILE_TYPES.CSV
      })
      setCsvFilter(undefined)
    } else if (
      _get(deploymentIdsReportError, 'errors.0.message') ||
      _get(deploymentIdsReportError, 'message') ||
      _get(deploymentIdsReportData, 'errors.0.message')
    ) {
      const errMessage = _get(deploymentIdsReportData, 'errors.0.message') || t('downloadLicensesCSVFail')
      enqueueSnackbar(errMessage, {
        variant: 'error'
      })
    }
  }, [deploymentIdsReportData, deploymentIdsReportError])

  useEffect(() => {
    if (_get(customerDeploymentIdsReportData, 'content') && _get(csvFilterCustomer, 'key', '--')) {
      enqueueSnackbar(t('downloadLicensesCSVSuccess'), {
        variant: 'success'
      })

      handleCreateFile(
        _get(csvFilterCustomer, 'key', '--'),
        'csv',
        _get(customerDeploymentIdsReportData, 'content', ''),
        {
          type: BLOB_FILE_TYPES.CSV
        }
      )
      setCsvFilter(undefined)
    } else if (
      _get(customerDeploymentIdsReportError, 'errors.0.message') ||
      _get(customerDeploymentIdsReportError, 'message') ||
      _get(customerDeploymentIdsReportData, 'errors.0.message')
    ) {
      const errMessage = _get(customerDeploymentIdsReportData, 'errors.0.message') || t('downloadLicensesCSVFail')
      enqueueSnackbar(errMessage, {
        variant: 'error'
      })
    }
  }, [customerDeploymentIdsReportData, customerDeploymentIdsReportError])

  return handleRenderTabs()
}

export default LicensedProductsContainer
