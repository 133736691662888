import { CloseIcon } from '@opswat/react-icon'
import { Box, DialogAdvanced, TextField, Tooltip, Typography, useTheme } from '@opswat/react-ui'
import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import { useLazyCustomerLicenseExpiringKeysReportQuery } from 'myopswat-web/src/api/license'
import StackBarChart from 'myopswat-web/src/components/CustomCharts/StackBarChart'
import { FC, useEffect, useMemo, useState } from 'react'
import CustomerCloudServicesSection from '../../sections/CloudServicesSection'
import CustomerLicenseDetailSection from '../../sections/LicensedProductsSection'

interface IProps {
  customerOrgIds: string[]
}

const ExpiringLicenseChart: FC<IProps> = ({ customerOrgIds }) => {
  const theme = useTheme()
  const [expiringData, setExpiringData] = useState<any>([])
  const [expiringDuration, setExpiringDuration] = useState<string>('30')
  const [open, setOpen] = useState(false)
  const [getExpiringKeysReport, { data: reportData, isFetching }] = useLazyCustomerLicenseExpiringKeysReportQuery()

  useEffect(() => {
    const numExpiringDuration = Number(expiringDuration)
    if (!isNaN(numExpiringDuration) && numExpiringDuration >= 0 && numExpiringDuration <= 1000) {
      const _search = _debounce(() => {
        setExpiringData([])
        getExpiringKeysReport({
          customerOrgIds: customerOrgIds,
          filters: { expiringDuration: Number(expiringDuration) }
        })
      }, 300)

      _search()

      return () => {
        _search.cancel()
      }
    }
  }, [expiringDuration])

  useEffect(() => {
    if (!isFetching && reportData) {
      const expiringData = Object.entries(_get(reportData, 'expiring_licenses', [])).map(([_, value]: any[]) => {
        return { name: value.name, count: value.count }
      })

      setExpiringData(expiringData)
    }
  }, [reportData, isFetching])

  const handleKeyDown = (e: any) => {
    if (['e', 'E', '+', '-', '.'].includes(e.key)) {
      e.preventDefault()
    }
  }

  const renderContainerBody = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography fontSize="24px" lineHeight="28px" color="#1B273C" fontWeight={400}>
          {totalCount} Licenses
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', height: 30 }}>
          <Typography variant="caption" color="#1B273C">
            Display all licenses expiring within the next&nbsp;&nbsp;
          </Typography>
          <Tooltip
            arrow
            title="Only numerical values ranging from 0 to 1000"
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: theme.palette.info.light,
                  color: 'rgba(0, 0, 0, 0.87)'
                }
              },
              arrow: {
                sx: {
                  color: theme.palette.info.light
                }
              }
            }}
          >
            <TextField
              size="small"
              hiddenLabel
              sx={{ minHeight: 24, height: 24, width: 50 }}
              type="tel"
              value={expiringDuration}
              inputProps={{
                onKeyDown: handleKeyDown,
                min: 0,
                max: 1000,
                style: {
                  padding: 2,
                  textAlign: 'center'
                }
              }}
              onChange={e => {
                const num = Number(e.target.value)
                if (num >= 0 && num <= 1000) setExpiringDuration(e.target.value)
              }}
            />
          </Tooltip>
          <Typography variant="caption" color="#1B273C">
            &nbsp;&nbsp;days
          </Typography>
        </Box>
      </Box>
    )
  }

  const renderTooltip = (name: string, count: number) => {
    return (
      <Box
        sx={{
          padding: '4px 8px',
          display: 'flex',
          flexDirection: 'column',
          gap: '4px'
        }}
      >
        <Typography fontSize="12px" fontWeight={500} color="#1B273C" lineHeight="16px" textAlign="center">
          {name}
        </Typography>
        <Typography fontSize="12px" fontWeight={400} color="#1B273C" lineHeight="16px" textAlign="center">
          Total no. of expiring
          <Typography
            component="span"
            fontSize="12px"
            fontWeight="400"
            lineHeight="16px"
            textAlign="center"
            color="#ED6706"
            marginLeft="8px"
          >
            {count}
          </Typography>
        </Typography>
      </Box>
    )
  }

  const totalCount = useMemo(() => {
    if (!expiringData) return 0
    return expiringData.reduce((prev: number, current: any) => prev + current.count, 0)
  }, [expiringData])

  return (
    <>
      <StackBarChart
        data={expiringData}
        containerTitle="EXPIRING LICENSES"
        containerSx={{ maxWidth: 500, display: 'flex', flexDirection: 'column', gap: 6 }}
        chartHeight={10}
        maxCategory={4}
        chartTitle="By organizations"
        onRenderContainerBody={renderContainerBody}
        onRenderTooltip={renderTooltip}
        onClickActionBtn={() => setOpen(true)}
        hint="Hover over each color bar to see Organization & Sub-Organization that has expiring licenses."
      />
      <DialogAdvanced
        open={open}
        onClose={() => setOpen(false)}
        iconClose={<CloseIcon />}
        dialogProps={{
          maxWidth: 'xxl'
        }}
        title={`${totalCount} Expiring Licenses`}
        content={
          <>
            <CustomerCloudServicesSection forViewAllModal expiringDuration={Number(expiringDuration)} />
            <Box sx={{ height: 8 }} />
            <CustomerLicenseDetailSection
              forViewAllModal
              expiringDuration={Number(expiringDuration)}
              disableFirstLoad={true}
            />
          </>
        }
      />
    </>
  )
}

export default ExpiringLicenseChart
