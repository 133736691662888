import _get from 'lodash/get'

import { FC, useCallback, useContext, useMemo } from 'react'
import { renderToString } from 'react-dom/server'

import { Box, Grid, Typography } from '@opswat/react-ui'

import CustomDonutChart from '../../components/charts/DonutChart'
import CustomerStatisticsItem from '../../components/CustomerStatisticsItem'

import { CustomerLicensesContext } from '../../interface'
import { getSeriesPercentages } from './utils'

const healthChartLabels = ['Active', 'Expiring', 'Expired']
const healthChartKeys = ['active', 'expiring', 'expired']
const usageChartLabels = ['In Use', 'Not In Use']
const usageChartKeys = ['in_use', 'not_in_use']

const healthChartColors = ['#148B14', '#FDB00D', '#CB0704']
const usageChartColors = ['#1D6BFC', '#8E939B']

const CustomerOverviewCharts: FC<any> = () => {
  const { reportData, handleOnClickChart } = useContext(CustomerLicensesContext)

  const healthChartSeries = [
    _get(reportData, ['status', 'active', 'license_count'], 0),
    _get(reportData, ['status', 'expiring', 'license_count'], 0),
    _get(reportData, ['status', 'expired', 'license_count'], 0)
  ]

  const usageChartSeries = [
    _get(reportData, ['availability', 'in_use', 'license_count'], 0),
    _get(reportData, ['availability', 'not_in_use', 'license_count'], 0)
  ]

  const healthChartOrgCount = [
    _get(reportData, ['status', 'active', 'organization_count'], 0),
    _get(reportData, ['status', 'expiring', 'organization_count'], 0),
    _get(reportData, ['status', 'expired', 'organization_count'], 0)
  ]

  const usageChartOrgCount = [
    _get(reportData, ['availability', 'in_use', 'organization_count'], 0),
    _get(reportData, ['availability', 'not_in_use', 'organization_count'], 0)
  ]

  const totalLicenses = useMemo(() => _get(reportData, 'total', 0), [reportData])

  const handleRenderStatisticItem = useCallback(
    (
      index: number,
      series: number[],
      colors: string[],
      labels: string[],
      filterKey: string,
      filterValues: string[],
      sx?: any
    ) => {
      return (
        <CustomerStatisticsItem
          value={series[index]}
          title={labels[index]}
          color={colors[index]}
          onClick={(label: string) => {
            handleOnClickChart(filterKey, filterValues[labels.indexOf(label)])
          }}
          sx={sx}
        />
      )
    },
    []
  )

  const handleRenderCustomerDonutChart = useCallback(
    (
      id: string,
      series: number[],
      colors: string[],
      labels: string[],
      orgSeries: number[],
      filterKey: string,
      filterKeys: string[]
    ) => {
      return (
        <CustomDonutChart
          id={id}
          series={series}
          colors={colors}
          labels={labels}
          tooltipComponent={(series: any, seriesIndex: number) => {
            const percentages = getSeriesPercentages(series)
            return renderToString(
              <div className="custom-chart-tooltip">
                <span className="custom-chart-tooltip-title">{percentages[seriesIndex]}%</span>
                <span className="custom-chart-tooltip-content">
                  {orgSeries[seriesIndex]} {`Organization${orgSeries[seriesIndex] > 1 ? 's' : ''}`}
                </span>
                <span className="custom-chart-tooltip-content">{series[seriesIndex]} Licenses</span>
              </div>
            )
          }}
          handleOnClickData={(label: string) => {
            handleOnClickChart(filterKey, filterKeys[labels.indexOf(label)])
          }}
        />
      )
    },
    []
  )

  return (
    <Grid item container xs={12}>
      <Grid
        item
        xs={12}
        lg={4}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          gap: '30px',
          marginBottom: {
            xs: 4,
            lg: 0
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            alignItems: {
              xs: 'center',
              lg: 'flex-start'
            },
            width: {
              xs: '100%',
              lg: 'auto'
            }
          }}
        >
          <Typography component="label" variant="subtitle1" textTransform="uppercase">
            Status
          </Typography>
          <Typography component="label" fontSize={'32px'} lineHeight={'32px'} fontWeight={500}>
            {totalLicenses} {totalLicenses === 1 ? 'License' : 'Licenses'}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          {healthChartLabels.map((_: any, index: number) =>
            handleRenderStatisticItem(
              index,
              healthChartSeries,
              healthChartColors,
              healthChartLabels,
              'licenseStatuses',
              healthChartKeys
            )
          )}
        </Box>
      </Grid>
      {totalLicenses === 0 && (
        <Grid item xs={12} lg={8} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography fontSize="10px" color="#707682">
            No data available
          </Typography>
        </Grid>
      )}
      {totalLicenses > 0 && (
        <>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              borderRight: '1px solid #E9EAEB',
              marginBottom: { xs: 4, lg: 0 }
            }}
          >
            {handleRenderCustomerDonutChart(
              'customer-license-health-chart',
              healthChartSeries,
              healthChartColors,
              healthChartLabels,
              healthChartOrgCount,
              'licenseStatuses',
              healthChartKeys
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            sx={{
              pl: 2,
              display: 'flex',
              gap: '16px',
              alignItems: {
                xs: 'center',
                md: 'flex-start'
              },
              flexDirection: {
                xs: 'column',
                md: 'row'
              }
            }}
          >
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box sx={{ flex: 7 }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    justifyContent: 'space-between',
                    height: '100%'
                  }}
                >
                  <Typography variant="subtitle1" color="#707682" textTransform="uppercase">
                    On-premises License Availability
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
                    {usageChartLabels.map((_: any, index: number) =>
                      handleRenderStatisticItem(
                        index,
                        usageChartSeries,
                        usageChartColors,
                        usageChartLabels,
                        'licenseAvailability',
                        usageChartKeys,
                        {
                          alignItems: 'flex-start'
                        }
                      )
                    )}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ flex: 5 }}>
                {handleRenderCustomerDonutChart(
                  'customer-license-usage-chart',
                  usageChartSeries,
                  usageChartColors,
                  usageChartLabels,
                  usageChartOrgCount,
                  'licenseAvailability',
                  usageChartKeys
                )}
              </Box>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default CustomerOverviewCharts
