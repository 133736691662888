import _get from 'lodash/get'

import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { useLazyCustomerLicenseReportQuery, useLazyCustomerOrganizationTreeQuery } from 'myopswat-web/src/api/license'
import { ICustomerCloudServicesFilters, ICustomerLicenseFiltersInput } from 'myopswat-web/src/api/license/types'

import CustomerLicensesPage from '.'
import { ALL_VALUE_OPTION } from '../MyLicensesPage/constants'
import { CustomerLicensesContext } from './interface'

import { DIALOGS_WEB } from 'myopswat-web/src/constants/dialogs'
import { toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import {
  selectCurrentOrgs,
  setCurrentOrgs
} from 'myopswat-web/src/containers/LicensedProductsContainer/licensedProductContainerSlice'
import { useAppDispatch, useTypedSelector } from 'myopswat-web/src/store'
import DialogInvalidCustomer from './dialogs/DialogInvalidCustomer'
import './styles.css'

interface IProps {
  permissions: any
  activateSuccessful: boolean
  setCsvFilter: (data: any) => void
}

const DEFAULT_PRODUCT_FILTERS = {
  q: '',
  productIds: [],
  licenseType: ALL_VALUE_OPTION,
  licenseStatuses: [],
  nearCapacity: false
}

const CustomerLicensesProvider: React.FC<IProps> = ({ permissions, setCsvFilter, activateSuccessful }) => {
  const dispatch = useAppDispatch()
  const currentOrgs = useTypedSelector(selectCurrentOrgs)

  const [getCustomerTree, { data: customerTreeData, isFetching: isFetchingCustomerTree }] =
    useLazyCustomerOrganizationTreeQuery()
  const [getCustomerLicenseReport, { data: reportData, isFetching: isGeneratingReport, error: reportError }] =
    useLazyCustomerLicenseReportQuery()

  const {
    control: controlProduct,
    getValues: getValuesProduct,
    setValue: setValueProduct
  } = useForm<ICustomerLicenseFiltersInput>({
    defaultValues: {
      customerOrgIds: [],
      filters: DEFAULT_PRODUCT_FILTERS
    }
  })

  const { control: controlCloud, setValue: setValueCloud } = useForm<ICustomerCloudServicesFilters>({
    defaultValues: {
      licenseStatuses: []
    }
  })

  const handleSaveCurrentOrgs = useCallback((orgList: any[]) => {
    dispatch(setCurrentOrgs(orgList))
  }, [])

  const handleResetSelection = useCallback(() => {
    getCustomerTree()
    setValueProduct('customerOrgIds', [])
    handleSaveCurrentOrgs([])
  }, [setValueProduct])

  const handleGenerateReport = useCallback(async () => {
    setValueProduct('filters', DEFAULT_PRODUCT_FILTERS)
    setValueCloud('licenseStatuses', [])
    await getCustomerLicenseReport({
      customerOrgIds: getValuesProduct('customerOrgIds').map((item: any) => item.value)
    }).then(() => {
      handleSaveCurrentOrgs(getValuesProduct('customerOrgIds'))
    })
  }, [setValueProduct, setValueCloud, getValuesProduct])

  const handleAutoGenerateReport = useCallback(
    async (orgList: any[]) => {
      setValueProduct('customerOrgIds', orgList)
      await getCustomerLicenseReport({
        customerOrgIds: orgList.map((item: any) => item.value)
      })
        .unwrap()
        .catch(error => {
          const codeName = _get(error, ['errors', 0, 'codeName'])
          if (codeName === 'ORGANIZATION_NOT_CUSTOMER') {
            dispatch(
              toggleDialogs({
                [DIALOGS_WEB.INVALID_CUSTOMER_ORGANIZATION]: true
              })
            )
          }
        })
    },
    [setValueProduct]
  )

  const handleOnClickChart = useCallback((key: string, value: any) => {
    setValueProduct('filters', {
      ...DEFAULT_PRODUCT_FILTERS,
      [`${key}`]: [value]
    })
    if (key === 'licenseStatuses') {
      setValueCloud('licenseStatuses', [value])
      document.getElementById('customer-cloud-services-section')?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    } else {
      setValueCloud('licenseStatuses', [])
      document.getElementById('customer-licensed-products-section')?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }, [])

  const organizationList = useMemo(() => {
    const options: any[] = []
    const treeData = _get(customerTreeData, 'trees', [])
    treeData.forEach((item: any) => {
      options.push({
        level: 0,
        label: item.name,
        value: item.id,
        isCustomer: item.isCustomer,
        styles: {
          color: '#154FBA'
        },
        children: item.children.map((child: any) => ({
          label: child.name,
          value: child.id
        }))
      })
      item.children.forEach((child: any) => {
        options.push({
          label: child.name,
          value: child.id,
          level: 1
        })
      })
    })
    return options
  }, [customerTreeData])

  const contextValue = useMemo(
    () => ({
      controlProduct,
      controlCloud,
      reportData,
      reportError,
      permissions,
      organizationList,
      activateSuccessful,
      isGeneratingReport,
      isFetchingCustomerTree,
      setValueProduct,
      getValuesProduct,
      setValueCloud,
      setCsvFilter,
      handleGenerateReport,
      handleOnClickChart,
      defaultProductFilters: DEFAULT_PRODUCT_FILTERS
    }),
    [
      permissions,
      activateSuccessful,
      controlProduct,
      organizationList,
      reportData,
      reportError,
      isGeneratingReport,
      isFetchingCustomerTree,
      setValueProduct,
      getValuesProduct,
      setCsvFilter,
      handleGenerateReport
    ]
  )

  useEffect(() => {
    getCustomerTree()
  }, [])

  useEffect(() => {
    if (currentOrgs.length > 0) {
      handleAutoGenerateReport(currentOrgs)
    }
  }, [currentOrgs])

  return (
    <CustomerLicensesContext.Provider value={contextValue}>
      <CustomerLicensesPage />
      <DialogInvalidCustomer handleResetSelection={handleResetSelection} />
    </CustomerLicensesContext.Provider>
  )
}
export default CustomerLicensesProvider
