import { ApexOptions } from 'apexcharts'
import { FC } from 'react'
import ReactApexChart from 'react-apexcharts'

interface IProps {
  id: string
  series: any[]
  colors: string[]
  labels: string[]
  options?: ApexOptions
  tooltipComponent?: (series: any, seriesIndex: number) => string
  handleOnClickData?: (label: string, value: any) => void
}

const CustomDonutChart: FC<IProps> = ({ id, series, colors, labels, options, tooltipComponent, handleOnClickData }) => {
  return (
    <ReactApexChart
      id={id}
      series={series}
      type="donut"
      width="200px"
      height="200px"
      options={{
        colors,
        labels,
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        tooltip: {
          custom: ({ series, seriesIndex }) => tooltipComponent?.(series, seriesIndex)
        },
        chart: {
          events: {
            dataPointSelection: (e : any, chartContext: any, config: any) => {
              const dataPointIndex = config.dataPointIndex
              const label = config.w.globals.labels[dataPointIndex]
              const value = config.w.config.series[dataPointIndex]
              handleOnClickData?.(label, value)
            }
          }
        },
        states: {
          active: {
            filter: {
              type: 'none' // Prevents darkening on click
            }
          },
          hover: {
            filter: {
              type: 'lighten',
              value: 0.1
            }
          }
        },
        ...options
      }}
    />
  )
}

export default CustomDonutChart
