import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-web/src/api/base-query'

import {
  ICustomerLicensePinInput,
  ICustomerCloudServicesFiltersInput,
  ICustomerLicenseFiltersInput,
  ILicenseCloudServicePinInput,
  ILicenseNoteAddInputV2,
  IOrganizationCloudServiceFilterInput,
  IProductLicenseFiltersInput,
  ICustomerCloudServicePinInput
} from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    organizationCloudProducts: builder.query<any, IOrganizationCloudServiceFilterInput>({
      query: filters => ({
        document: `
          query ($filters: OrganizationCloudServiceFilterInput) {
            organizationsCloudServices (filters: $filters) {
              id
              name
              children
              cloudServices
              totalKeys
              viewMdcPermission
              serviceNames
            }
          }
        `,
        variables: {
          filters
        }
      })
    }),
    organizationLicensedProducts: builder.query<any, IProductLicenseFiltersInput>({
      query: ({ filters, pinnedOnly }) => ({
        document: `
          query organizationProductsLicenseKeys($filters: ProductWithLicenseKeysFiltersInput, $pinnedOnly: Boolean) {
            organizationProductsLicenseKeys(filters: $filters, pinnedOnly: $pinnedOnly) {
              id
              name
              products {
                id
                name
                slug
                licenses
                children
              }
              children
              totalKeys
              hiddenKeys
              displayMode
            }
          }
        `,
        variables: {
          filters: filters ?? {},
          pinnedOnly
        }
      })
    }),
    organizationTree: builder.query<any, void>({
      query: () => ({
        document: `
          query {
            currentOrganizationTree {
              tree {
                id
                name
                children
              }
              flatNodes {
                id
                name
              }
              licenseDisplayMode
            }
          }
        `
      })
    }),
    organizationLicenseKeyReport: builder.query<any, any>({
      query: ({ id }) => ({
        document: `
          query licenseKeyReport($id: UUID!) {
            licenseKeyReport(id: $id) {
              content
            }
          }
        `,
        variables: {
          id
        }
      })
    }),
    organizationLicenseHistory: builder.query<any, any>({
      query: ({ productId, orgId }) => ({
        document: `
          query productHistoryLicenseV2($productId: UUID!, $orgId: UUID!) {
            productHistoryLicenseV2(productId: $productId, orgId: $orgId) {
              products {
                id
                slug
                name
                tags
                children {
                  id
                  slug
                  licenses
                  name
                }
                licenses
                updatedAt
                createdAt
              }
              engineInfo {
                activationKey
                activatedDate
                productId
                platform
                type
                groupedEngines {
                  name
                  description
                  engines {
                    pattern
                    name
                  }
                }
                addedEngines {
                  pattern
                  name
                }
                isAllEngine
              }
            }
          }
        `,
        variables: {
          productId,
          orgId
        }
      })
    }),
    addOrganizationLicenseNote: builder.mutation<any, ILicenseNoteAddInputV2>({
      query: ({ id, content }) => ({
        document: gql`
          mutation licenseNoteUpdateV2($input: LicenseNoteInputV2!) {
            licenseNoteUpdateV2(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: {
            id,
            content
          }
        }
      })
    }),
    pinOrganizationLicense: builder.mutation<any, string>({
      query: id => ({
        document: gql`
          mutation licenseKeyPin($input: LicenseKeyPinInput!) {
            licenseKeyPin(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: {
            id
          }
        }
      })
    }),
    unpinOrganizationLicense: builder.mutation<any, string>({
      query: id => ({
        document: gql`
          mutation licenseKeyUnpin($input: LicenseKeyPinInput!) {
            licenseKeyUnpin(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: {
            id
          }
        }
      })
    }),
    pinUnpinCloudProducts: builder.mutation<any, ILicenseCloudServicePinInput>({
      query: input => ({
        document: gql`
          mutation licenseCloudServicePin($input: LicenseCloudServicePinInput!) {
            licenseCloudServicePin(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input
        }
      })
    }),
    customerOrganizationTree: builder.query<any, void>({
      query: () => ({
        document: `
          query {
            customerOrganizationsTrees {
              trees {
                id
                name
                accountId
                ssoId
                isCustomer
                children {
                  id
                  name
                  accountId
                  ssoId
                  isCustomer
                }
              }
            }
          }
        `
      })
    }),
    customerLicenseReport: builder.query<any, any>({
      query: ({ customerOrgIds, filters }) => ({
        document: `
          query organizationCustomerLicenseKeysReport($customerOrgIds: [UUID]!, $filters: CustomerOrganizationLicenseKeysFiltersInput) {
            organizationCustomerLicenseKeysReport(customerOrgIds: $customerOrgIds, filters: $filters)
          }
        `,
        variables: {
          customerOrgIds,
          filters
        }
      })
    }),
    customerLicenseExpiringKeysReport: builder.query<any, any>({
      query: ({ customerOrgIds, filters }) => ({
        document: `
          query organizationCustomerLicenseKeysExpiringInReport($customerOrgIds: [UUID]!, $filters: CustomerOrganizationLicenseKeysFiltersInput) {
            organizationCustomerLicenseKeysExpiringInReport(customerOrgIds: $customerOrgIds, filters: $filters)
          }
        `,
        variables: {
          customerOrgIds,
          filters
        }
      })
    }),
    customerLicensedProducts: builder.query<any, ICustomerLicenseFiltersInput>({
      query: ({ pinnedOnly, customerOrgIds, filters }) => ({
        document: `
          query organizationCustomerLicenseKeys($pinnedOnly: Boolean, $customerOrgIds: [UUID]!, $filters: CustomerOrganizationLicenseKeysFiltersInput) {
            organizationCustomerLicenseKeys(
              pinnedOnly: $pinnedOnly
              customerOrgIds: $customerOrgIds,
              filters: $filters
            ) {
              orgs {
                id
                name
                isEmptyLicense
                products {
                  id
                  name
                  slug
                  licenses
                  children
                }
                children
              }
              hiddenKeys
              totalKeys
            }
          }
        `,
        variables: {
          pinnedOnly,
          customerOrgIds,
          filters
        }
      })
    }),
    customerCloudProducts: builder.query<any, ICustomerCloudServicesFiltersInput>({
      query: ({ filters, customerOrgIds }) => ({
        document: `
          query ($filters: OrganizationCloudServiceFilterInput, $customerOrgIds: [UUID]!) {
            customerOrganizationsCloudServices(
              filters: $filters
              customerOrgIds: $customerOrgIds
            ) {
              orgs {
                id
                name
                totalKeys
                viewMdcPermission
                serviceNames
                isCustomer
                children
                cloudServices
                isEmptyLicense
              }
              totalKeys
            }
          }
        `,
        variables: {
          filters,
          customerOrgIds
        }
      })
    }),
    pinUnpinCustomerCloudProducts: builder.mutation<any, ICustomerCloudServicePinInput>({
      query: input => ({
        document: gql`
          mutation customerLicenseCloudServicePin($input: CustomerLicenseCloudServicePinInput!) {
            customerLicenseCloudServicePin(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input
        }
      })
    }),
    pinUnpinCustomerLicensedProducts: builder.mutation<any, ICustomerLicensePinInput>({
      query: input => ({
        document: gql`
          mutation customerLicenseKeyPin($input: CustomerLicenseKeyPinInput!) {
            customerLicenseKeyPin(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input
        }
      })
    }),
    checkHasCustomer: builder.query<any, void>({
      query: () => ({
        document: `
          query {
            checkCustomerOrgsExist
          }
        `
      })
    }),
    customerLicenseHistory: builder.query<any, any>({
      query: ({ productId, orgId }) => ({
        document: `
          query customerProductHistoryLicenseV2($productId: UUID!, $orgId: UUID!) {
            customerProductHistoryLicenseV2(productId: $productId, orgId: $orgId) {
              products {
                id
                slug
                name
                tags
                children {
                  id
                  slug
                  licenses
                  name
                }
                licenses
                updatedAt
                createdAt
              }
              engineInfo {
                activationKey
                activatedDate
                productId
                platform
                type
                groupedEngines {
                  name
                  description
                  engines {
                    pattern
                    name
                  }
                }
                addedEngines {
                  pattern
                  name
                }
                isAllEngine
              }
            }
          }
        `,
        variables: {
          productId,
          orgId
        }
      })
    }),
    customerLicenseKeyReport: builder.query<any, any>({
      query: ({ id }) => ({
        document: `
          query customerLicenseKeyReport($id: UUID!) {
            customerLicenseKeyReport(id: $id) {
              content
            }
          }
        `,
        variables: {
          id
        }
      })
    })
  })
})

export const {
  useLazyOrganizationTreeQuery,
  useOrganizationLicenseKeyReportQuery,
  useLazyOrganizationCloudProductsQuery,
  useLazyOrganizationLicensedProductsQuery,
  useLazyOrganizationLicenseHistoryQuery,
  useAddOrganizationLicenseNoteMutation,
  usePinOrganizationLicenseMutation,
  useUnpinOrganizationLicenseMutation,
  usePinUnpinCloudProductsMutation,
  useLazyCustomerOrganizationTreeQuery,
  useLazyCustomerLicensedProductsQuery,
  useLazyCustomerLicenseReportQuery,
  useLazyCustomerCloudProductsQuery,
  usePinUnpinCustomerCloudProductsMutation,
  usePinUnpinCustomerLicensedProductsMutation,
  useCheckHasCustomerQuery,
  useLazyCustomerLicenseHistoryQuery,
  useCustomerLicenseKeyReportQuery,
  useLazyCustomerLicenseExpiringKeysReportQuery
} = api
