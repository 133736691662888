import { useEffect, useMemo, useState } from 'react'

import _get from 'lodash/get'

import { fullName } from '@opswat/react-core'
import { Box, Grid, TemplateSection, Typography, TypographyDivider } from '@opswat/react-ui'

import { useLazyOrganizationSupportInfoQuery } from 'myopswat-web/src/api/support'
import { useLazySystemValueListQuery } from 'myopswat-web/src/api/system'
import DialogSubmitCase from 'myopswat-web/src/components/Dialog/DialogSubmitCase/index'
import { useTypedSelector } from 'myopswat-web/src/store'
import { SYSTEM_VALUE_KEYS } from 'myopswat-web/src/constants'

import SupportAvailable from './SupportAvailable'
import SupportLoading from './SupportLoading'
import SupportNotAvailable from './SupportNotAvailable'
import useGetPortalPermissions from '../../hooks/useGetPortalPermissions'
import useHasPermissions from '../../hooks/useHasPermissions'

const SupportServicesPage = () => {
  const { permissions } = useGetPortalPermissions()
  const hasViewSupportServicePerm = useHasPermissions({targetPerms: ['view_support_services', 'full_support_services'], userPerms: permissions})
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false)
  const profileData = useTypedSelector(state => state?.api?.queries?.['profile(undefined)']?.data)

  const [getOrganizationSupportInfo, { data, isFetching }] = useLazyOrganizationSupportInfoQuery()
  const [getSystemValueList, { data: systemValueInfo }] = useLazySystemValueListQuery()

  const systemValueIn = useMemo(() => {
    if (systemValueInfo) {
      return systemValueInfo
    }
    else {
      return {}
    }
  },[systemValueInfo])

  useEffect(() => {
    getSystemValueList([SYSTEM_VALUE_KEYS.SUPPORT_SERVICES_PHONE, SYSTEM_VALUE_KEYS.SUPPORT_SUBMIT_CASE_PRODUCTS])
  }, [])

  useEffect(() => {
    if (profileData) {
      getOrganizationSupportInfo(_get(profileData, 'currentOrganizationId') ?? '')
    }
  }, [profileData])

  const isAvailable = useMemo(() => {
    return data?.supportInfo != null
  }, [data])

  const SupportInfo = useMemo(() => {
    return isAvailable && hasViewSupportServicePerm ? (
      <SupportAvailable
        isFetching={isFetching}
        data={data}
        openSubmitCaseDialog={() => setIsOpenDialog(true)}
        systemValueInfo={systemValueIn}
        permissions={permissions}
      />
    ) : (
      <SupportNotAvailable data={data} />
    )
  }, [isFetching, data])

  return (
    <TemplateSection>
      <Box>
        <TypographyDivider label="Support" />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: '22px',
              lineHeight: '32px'
            }}
          >
            {'Hello, '}
            <span style={{ textTransform: 'capitalize' }}>
              {fullName(_get(profileData, 'firstName'), _get(profileData, 'lastName'))}
            </span>
            {'! How can we help you today?'}
          </Typography>
        </Grid>
      </Grid>
      {isFetching ? <SupportLoading /> : SupportInfo}
      <DialogSubmitCase
        openDialog={isOpenDialog}
        handleCloseDialog={() => setIsOpenDialog(false)}
        productList={_get(
          systemValueInfo?.find((item: any) => item.key === SYSTEM_VALUE_KEYS.SUPPORT_SUBMIT_CASE_PRODUCTS),
          'data',
          []
        )}
      />
    </TemplateSection>
  )
}

export default SupportServicesPage
