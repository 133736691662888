import React, { FC, useLayoutEffect, useRef, useState } from 'react'

import { Box, Link, Tooltip, Typography } from '@opswat/react-ui'

interface IProps {
  icon: React.ReactNode
  label: React.ReactNode
  link?: string
  fontSize?: string
}

const BoxResource: FC<IProps> = ({ icon, label, link, fontSize = '14px' }) => {
  const ref = useRef<any>()
  const [hasEllipsis, setHasEllipsis] = useState<boolean>(false)

  const handleCheckEllipsis = () => {
    setHasEllipsis(ref.current.clientWidth < ref.current.scrollWidth)
  }

  useLayoutEffect(() => {
    handleCheckEllipsis()
    window.addEventListener('resize', handleCheckEllipsis)

    return () => {
      window.removeEventListener('resize', handleCheckEllipsis)
    }
  }, [ref])

  return (
    <Tooltip
      arrow
      placement="right"
      title={hasEllipsis && label}
      componentsProps={{
        tooltip: {
          sx: {
            color: '#1B273C',
            backgroundColor: '#E9EAEB',
            maxWidth: '400px'
          }
        },
        arrow: {
          sx: {
            color: '#E9EAEB'
          }
        }
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F4F4F5',
          padding: '12px',
          '&:hover': {
            backgroundColor: '#EFF4FF',
            cursor: 'pointer'
          }
        }}
        component={Link}
        href={link || ''}
        target="_blank"
        underline="none"
      >
        <Box
          sx={{
            pr: 1.5,
            py: 1.25,
            borderRight: '1px solid #D2D4D6'
          }}
        >
          <Box sx={{ width: '16px', height: '16px' }}>{icon}</Box>
        </Box>
        <Typography noWrap ref={ref} sx={{ paddingInline: '12px', fontSize, lineHeight: '20px' }}>
          {label}
        </Typography>
      </Box>
    </Tooltip>
  )
}

export default BoxResource
